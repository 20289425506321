import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: '/not-found'
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/auth.vue'),
    meta: {
      title: "认证"
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: {
      title: "主页"
    },
    children:[
      {
        path:"not-found",
        component: () => import('../views/tab/NotFound.vue'),
        meta: {
          title: "404"
        }
      },
      {
        path:"index",
        component: () => import('../views/tab/index.vue'),
        meta: {
          title: "首页"
        }
      },
      {
        path:"server",
        component: () => import('../views/tab/server.vue'),
        meta: {
          title: "搜索服务器"
        }
      },
      {
        path:"server/info",
        component: () => import('../views/tab/server/info.vue'),
        meta: {
          title: "服务器详情"
        }
      },
      {
        path:"player",
        component: () => import('../views/tab/player.vue'),
        meta: {
          title: "搜索玩家"
        }
      },
      {
        path:"player/info",
        component: () => import('../views/tab/player/info.vue'),
        meta: {
          title: "玩家详情"
        }
      },
      {
        path:"center",
        component: () => import('../views/tab/center.vue'),
        meta: {
          title: "个人中心"
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta['title']) {
    document.title = to.meta['title']
  }
  if (to.path === '/') {
    next("/index")
  }
  else {
    next()
  }
})

export default router
